import React from 'react';
import Table from 'react-bootstrap/Table';
import * as rdd from 'react-device-detect';
import './Detector.css';

const Detector = (props) => {
    return <Table responsive>
    <thead>
      <tr><th colSpan={2}>如無法登入或播放影片，請依指示提供以下瀏覽器與裝置版本資訊給協會工作人員。</th></tr>
      <tr>
        <th>屬性</th>
        <th>值</th>
      </tr>
    </thead>
    <tbody>
    <tr><td>UA</td><td>{rdd.getUA}</td></tr>
    <tr><td>osName</td><td>{rdd.osName}</td></tr>
    <tr><td>osVersion</td><td>{rdd.osVersion}</td></tr>
    <tr><td>BrowserName</td><td>{rdd.browserName}</td></tr>
    <tr><td>mobileModel</td><td>{rdd.mobileModel}</td></tr>
    <tr><td>mobileVendor</td><td>{rdd.mobileVendor}</td></tr>
    <tr><td colSpan="2">
    { (rdd.isAndroid) ? <span>isAndroid</span> :  null }
    { (rdd.isBrowser) ? <span>isBrowser</span> :  null }
    { (rdd.isChrome) ? <span>isChrome</span> : null }
    { (rdd.isConsole) ? <span>isConsole</span> : null }
    { (rdd.isChromium) ? <span>isChromium</span> : null }
    { (rdd.isElectron) ? <span>isElectron</span> : null }
    { (rdd.isEdge) ? <span>isEdge</span> : null }
    { (rdd.isEdgeChromium) ? <span>isEdgeChromium</span> : null }
    { (rdd.isFirefox) ? <span>isFirefox</span> : null }
    { (rdd.isIE) ? <span>isIE</span> : null }
    { (rdd.isDesktop) ? <span>isDesktop</span> : null }
    { (rdd.isIOS) ? <span>isIOS</span> : null }
    { (rdd.isIOS13) ? <span>isIOS13</span> : null }
    { (rdd.isIPad13) ? <span>isIPad13</span> : null }
    { (rdd.isIPhone13) ? <span>isIPhone13</span> : null }
    { (rdd.isMacOs) ? <span>isMacOs</span> : null }
    { (rdd.isSafari) ? <span>isSafari</span> : null }
    { (rdd.isMobileSafari) ? <span>isMobileSafari</span> : null }
    { (rdd.isSamsungBrowser) ? <span>isSamsungBrowser</span> : null }
    { (rdd.isMobile) ? <span>isMobile</span> : null }
    { (rdd.isTablet) ? <span>isTablet</span> : null }
    { (rdd.isWindows) ? <span>isWindows</span> : null }
    </td></tr>
    </tbody>
  </Table>;
};

export { Detector };