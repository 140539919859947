import React from 'react';
import { Link } from "react-router-dom";
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import SectionFiles from './SectionFiles';

import {
  Accordion,
  Card,
  ListGroup,
  ListGroupItem
} from 'react-bootstrap';

const Course = (props) => {

  const { data, classId, classLogs } = props;
  const course = (data) ? JSON.parse( data.courseData ) : {};
  
  if( data == null ) return <></>;
  
  return (
    <Accordion defaultActiveKey={course.chapters[0].id}>
      {
        course.chapters.map( (chapter, idx_chapter) => {
          return (
            <Card key={idx_chapter}>
              <Card.Header as="h3">
                {chapter.name}
              </Card.Header>
              <Card.Body>
                {chapter.sections.map((section, idx_section) => 
                  <div key={idx_section} id={`SEC-${idx_chapter}-${idx_section}`}>
                    <h4 style={{paddingTop:.4+'em'}}>{section.name}</h4>
                    <ListGroup as="ol">{
                      section.videos.map((v, vseq) =>
                        <ListGroupItem key={chapter.id + '_' + idx_section + '_' + vseq}>
                            { (classLogs) ? (
                                (classLogs[v.id] && classLogs[v.id].includes(3) && classLogs[v.id].includes(4) && classLogs[v.id].includes(5)) ? 
                                <span style={{fontSize:'small'}}> 已完成 <CheckIcon /> | &nbsp;</span> : 
                                <span style={{fontSize:'small'}}> 未完成 <CloseIcon /> | &nbsp;</span>)
                               : <><span style={{fontSize:'small'}}> 載入中... | &nbsp;</span></>
                            } 
                          <Link to={{ pathname: `/${classId}/video-${idx_chapter}-${idx_section}-${vseq}` }}>
                            <VideoLibraryIcon /> {v.title} 
                          </Link>
                        </ListGroupItem>
                      )}
                    </ListGroup>
                    <SectionFiles idx={idx_chapter+'_'+idx_section} classId={classId} section={section}></SectionFiles>
                  </div>
                )}
              </Card.Body>
            </Card>
          );
        })
      }
    </Accordion>);
}

export { Course };