import React from "react";

const SectionFiles = props => {

  const hasFile = (section) => {
    return section.files && (section.files.length > 0)
  };

  const hasLink = (section) => {
    return section.links && (section.links.length > 0)
  };
  
  let { idx, classId, section } = props;
  if ( hasFile(section) || hasLink(section) ) {
    return (
    <div className="section-download">
      <h5>參考資料：</h5>
      <ol>
        {(hasFile(section)) ? section.files.map((file, file_idx) =>
          <li key={idx+file_idx}><a href={'/files/' + classId + '/' + file.name}>{file.name}</a></li>
          ) : <></> 
        }
        {(hasLink(section)) ? 
        section.links.map((link, link_idx) =>
          <li key={idx+link_idx}><a href={link.url}>{link.name}</a></li>
          ) : <></>
        }
      </ol>
    </div>
    );
  } else {
    return <></>;
  }
};

export default SectionFiles;