import Repo from './Base';
import authService from '../components/api-authorization/AuthorizeService';

const resource = '/me';

export default {

  async getMe() {
    const token = await authService.getAccessToken();
    let result = await Repo.get(
      `${resource}`, 
      { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
    if (result.status === 200) {
      return result.data;
    } else {
      throw result;
    }
  },

  async getClasses() {
    const token = await authService.getAccessToken();
    let result = await Repo.get(
      `${resource}/classes`, 
      { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
    if (result.status === 200) {
      return result.data;
    } else {
      throw result;
    }
  },

  async checkAgreement(classId) {
    const token = await authService.getAccessToken();
    let result = await Repo.post(
      `/Classes/${classId}/acceptAgreement`, "", 
      { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
    if (result.status === 200) {
      let retval = result.data;
      return true;
    } else {
      return false;
    }
  },
  
  async getVideoLogs(classIdId) {
    const token = await authService.getAccessToken();
    let result = await Repo.get(
      `${resource}/classes/${classIdId}/videoLogs`, 
      { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
    if (result.status === 200) {
      return result.data;
    } else {
      throw result;
    }
  },
  
};


