import Repo from './Base';
import authService from '../components/api-authorization/AuthorizeService';

const resource = '/videos';

export default {

  async getOtp( id ) {
    const token = await authService.getAccessToken();
    let result = await Repo.post(
      `${resource}/${id}/otp`, "", 
      { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
    if (result.status === 200) {
      let data = result.data;
      const otpdata = JSON.parse(data.otp);
      let retval = { otp: otpdata.otp, vid: data.id };
      return retval;
    } else {
      throw result;
    }
  },
 
};


