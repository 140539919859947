import React, { useRef, useEffect, useContext, useState } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { HashLink } from 'react-router-hash-link';
import RepoVideo from '../repository/RepoVideo';
import authService from './api-authorization/AuthorizeService';
import NextVideo from './NextVideo';
import { UserContext } from "../App";
import { ExitToApp } from '@material-ui/icons';

async function sendVideoAction(video_id, action_id, class_id) {
  const token = await authService.getAccessToken();
  await fetch(`videos/${video_id}/actions/${action_id}?class=${class_id}`, {
    method: 'POST',
    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
  });
}

const Video = (props) => {

  const videoDiv = useRef(null);
  const { myClasses } = useContext(UserContext);
  const [ isEnded, setIsEnded ] = useState( "未完成" );
  
  let player_state = null; 

  let { classId, chapterIdx, sectionIdx, videoIdx } = props.match.params;

  const targetClass = (myClasses.length && myClasses.length > 0) ? myClasses.find( x=> x.id == classId ) : null;

  const course = (targetClass != null) ? JSON.parse(targetClass.courseData) : { chapters: [ { sections: [ { videos: [ { title: "" }] } ]}] };

  chapterIdx = parseInt(chapterIdx);
  sectionIdx = parseInt(sectionIdx);
  videoIdx = parseInt(videoIdx);
  const chapter = course.chapters[chapterIdx];
  const section = chapter.sections[sectionIdx];
  const video = section.videos[videoIdx];
  
  if( video != null ) sendVideoAction(video.id, 1, classId); //switch to page

  function isLastSection(){
    return (sectionIdx === (chapter.sections.length - 1));
  }
  
  function isLastVideo(){
    return (videoIdx === (section.videos.length - 1));
  }
  
  function isLastChapter(){
    return (chapterIdx === (course.chapters.length - 1));
  }
    
  function nextVideo(){
    if( isLastVideo() ){
      if( isLastSection() ){
        if( isLastChapter() ){
          return null;
        } 
        return { 
          classId: classId,
          chapterIdx: chapterIdx+1,
          sectionIdx: 0,
          videoIdx: 0,
          title: course.chapters[chapterIdx+1].sections[0].videos[0].title
        };
      }
      return { 
        classId: classId,
        chapterIdx: chapterIdx,
        sectionIdx: sectionIdx+1,
        videoIdx: 0,
        title: course.chapters[chapterIdx].sections[sectionIdx+1].videos[0].title
      };
    }
    return { 
      classId: classId,
      chapterIdx: chapterIdx,
      sectionIdx: sectionIdx,
      videoIdx: videoIdx+1,
      title: course.chapters[chapterIdx].sections[sectionIdx].videos[videoIdx+1].title
    };
}

  async function loadVideo(){
      try {
        var { otp, vid } = await RepoVideo.getOtp(video.id);
      } catch(e) {
        console.log(e);
        alert('登入逾時、請重新登入系統');
        window.location = '/authentication/login';
      }
      console.log("load video-----");
      var v = new window.VdoPlayer({
        otp: otp,
        playbackInfo: btoa(JSON.stringify({
          videoId: vid
        })),
        theme: "9111e0056d664282b368c5558a36f32a",
        // the container can be any DOM element on website
        container: videoDiv.current
      });
      var registered30 = false;
      var registered50 = false;
      var registered90 = false;
      var lastStatus = 0;
      // get reference to player
      v.addEventListener('mpmlLoad', () => {
        console.log("mpm-Load");
        var htmlStr = "<a href='https://www.w3.org' style='cursor:pointer;position:absolute;left:10px;top:10px;font-size:24px;font-weight:bolder' target='_blank'>W3C</a>";
        // v.injectThemeHtml(htmlStr);
      });
      v.addEventListener("load", function(data){
        console.log("load event called" , data);
      });
      v.addEventListener("play", function(){
        sendVideoAction(video.id, 2, classId); //play
        console.log("play event called");
      });
      v.addEventListener("pause", function(){
        console.log("pause event called");
      });
      v.addEventListener("ended", function(data){
        console.log("ended event called" , data);
        if( registered30 && registered50 && registered90 ){
          // 用戶端檢查，應該是播放完畢。強制重新設定一次三個檢查點避免遺漏。
          sendVideoAction(video.id, 3, classId); // 30% 
          sendVideoAction(video.id, 4, classId); // 50% 
          sendVideoAction(video.id, 5, classId); // 90%
          sendVideoAction(video.id, 6, classId); // Finish
        } else {
          window.title = "播放內容紀錄：" + v.totalPlayed + ":" + v.totalCoveredArray.length;
//        alert("根據紀錄您的影片只完成部分 (" + v.totalPlayed + "/" + v.totalCoveredArray.length + ")、記得回頭補足整段影片。");
//        console.log(v.totalCoveredArray);
          sendVideoAction(video.id, 7, classId); // Ending while not finished all checkpoints
        }
      });
      v.addEventListener("seeking", function(){
        console.log("seeking event called");
      });

      let count = 0;
      let ended = false;
      v.addEventListener("progress", function({status}){
        let { currentTime, duration } = status;
        let play_status = status.status;
        let view_ratio = currentTime / duration;
        if( v.ended ){
          if(!ended){
            // the "progress" event keep firing after the video stops.
            ended = true;
            console.log("progress ended-!");
            console.log(play_status);
            console.log( status );
          }
          return;
        }
        if( !registered30 && (view_ratio > 0.3 && view_ratio < 0.35) ){
          registered30 = true;
          sendVideoAction(video.id, 3, classId); // 30% 
        }
        if( !registered50 && (view_ratio > 0.5 && view_ratio < 0.55) ){
          registered50 = true;
          sendVideoAction(video.id, 4, classId); // 50%
        }
        if( !registered90 && (view_ratio > 0.88 && view_ratio < 0.92) ){
          registered90 = true;
          sendVideoAction(video.id, 5, classId); // 90%
        }
      });
  }

  useEffect(() => {
    if( myClasses.length > 0) loadVideo();
  });

  return (
    (targetClass==null) ? <></> : 
    <Container>
      <Row>
        <Col>
          <h2>{section.name}</h2>
          <h4>{video.title}</h4>
        </Col>
      </Row>
      <Row>
        <Col>
          <div ref={videoDiv}></div>
        </Col>
      </Row>
      <Row>
        <Col lg={true}>
          <HashLink to={`/${classId}#SEC-${chapterIdx}-${sectionIdx}`}>
            <Button className="float-left">^ 回課程大綱</Button>
          </HashLink>
          <NextVideo nextVideo={nextVideo()} />
        </Col>
      </Row>
    </Container>    
  );
}

export { Video };