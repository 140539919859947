import React from "react";
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

const NextVideo = props => {
  let { nextVideo } = props;
  if ( nextVideo ) {
    return (
      <Link to={{ pathname: `/${nextVideo.classId}/video-${nextVideo.chapterIdx}-${nextVideo.sectionIdx}-${nextVideo.videoIdx}`}}>
        <Button className="float-right">{nextVideo.title} &gt;&gt;</Button>
      </Link>
    );
  } else {
    return null;
  }
};

export default NextVideo;