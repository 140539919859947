import axios from 'axios'

// let baseUrl = "https://eqvod.metaengine.org";

//const baseUrl = process.env.APP_REPOSITORY_URL;
const baseUrl = "";

var repo = axios.create({
  headers: { 'Content-Type': 'application/json', },
  baseURL: window.location.origin
});

export default repo;
export const BASE_URL = baseUrl;

