import React, { useState, useEffect, createContext } from 'react';
import { BrowserRouter, Route ,Switch, useLocation } from 'react-router-dom'
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Video } from './components/Video';
import { Detector } from './components/Detector';
import EQClass from './components/EQClass';
import authService from './components/api-authorization/AuthorizeService';

import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import RepoMe from './repository/RepoMe';

import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';

import './custom.css'

export const UserContext = createContext(null);

export default function App(){

  const location = useLocation();
  const [myClasses, setMyClasses] = useState(-1);
  const [user, setUser] = useState(null);
  const [loadError, setLoadError] = useState(null);

  async function populateState() {
    const [isAuth] = await Promise.all([authService.isAuthenticated(), authService.getUser()]);
    let me = {};
    let classes = [];
    try{ 
      me = await RepoMe.getMe();
      classes = await RepoMe.getClasses();
    } catch (err){
      setLoadError(err);
      if(isAuth){
        if( !location.pathname.startsWith(ApplicationPaths.ApiAuthorizationPrefix) ){
          window.location = "/authentication/login";
        }
      } else {
        console.log(err);
      }
    }
    setUser(me);
    setMyClasses(classes);
    if( !isAuth ) 
      console.log("--- not authenticated.");
  };

  useEffect(()=> {
    var x = authService.subscribe(()=> { 
      populateState();
    });
    populateState();
    return () => { authService.unsubscribe(x); }
   } , []);

  return (
    <UserContext.Provider value={{myClasses, user, loadError}}>
    <BrowserRouter>
      <Layout>
        <Switch>
          <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
          <Route exact path='/t' component={Detector} />
          <AuthorizeRoute  exact path='/' component={Home} />
          <AuthorizeRoute  exact path='/:classId' component={EQClass} />
          <AuthorizeRoute  path='/:classId/video-:chapterIdx-:sectionIdx-:videoIdx' component={Video} />
        </Switch>
      </Layout>
    </BrowserRouter>
    </UserContext.Provider>
  );
}
