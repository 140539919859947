import React, { useEffect, useState, useContext } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import authService from './api-authorization/AuthorizeService';
import { getUA, isSafari, isDesktop, isMacOs } from "react-device-detect";
import { UserContext } from "../App";
import { Link } from 'react-router-dom';

const Courses = () => {
  const { myClasses, loadError } = useContext(UserContext);
  if( myClasses == -1 ){
    return <Card>
    <Card.Body>
        <Card.Title>資料載入中</Card.Title>
        <Card.Text>
          請稍待...
        </Card.Text>
      </Card.Body>
    </Card>;
  }
  if(loadError != null){
    return <Card>
    <Card.Body>
        <Card.Title>登入逾時</Card.Title>
        <Card.Text>
          嘗試重新登入課程平台
        </Card.Text>
      </Card.Body>
    </Card>;
  } else {
    return  (myClasses.length > 0) ? <Container>{
      myClasses.map( x => <Card key={x.id}>
        <Card.Body>
          <Card.Title>{x.name}</Card.Title>
          <Card.Text>
            {x.startDate.substr(0,10)} ~ {x.endDate.substr(0,10)}
          </Card.Text>
          <Link to={{pathname: `/${x.id}`}} ><Button variant="primary">進入課程網頁</Button></Link>
        </Card.Body>
      </Card> )
      }</Container> : 
      <Card>
      <Card.Body>
          <Card.Title>目前沒有進行中的課程</Card.Title>
          <Card.Text>
            請參照協會官網公告課程起、迄時間，上線觀看課程。
          </Card.Text>
        </Card.Body>
      </Card> ;
    }
}

const Home = (props) => {

  const [isAuthenticated, setAuth] = useState(false); 
  const [userName, setUserName] = useState("");

  async function populateState() {
    const [isAuth, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()]);
    setAuth(isAuth);
    setUserName( user && user.name );
    if(!isAuth) console.log("not authenticated.");
  };

  useEffect(()=> {
    var x = authService.subscribe(()=> { 
      console.log("==== auth service subscribed.")
      populateState();
    });
    populateState();
    return () => { authService.unsubscribe(x); }
   } , []);

  
  if (isMacOs && isSafari && isDesktop) {
    return <Card>
      <Card.Header><h2>相容性提示</h2></Card.Header>
      <Card.Body>
        <h4>
          課程網站目前在 MacOS 僅支援 Chrome 與 Firefox 瀏覽器，使用 Safari 的學員請先下載安裝 <a href="https://www.google.com/chrome/">Chrome 瀏覽器</a>。
        </h4>
      </Card.Body>
    </Card>;
  }

  return (
    ( getUA.indexOf("Line") > -1 ) ?
      <Card>
        <Card.Header><h2>相容性提示</h2></Card.Header>
        <Card.Body>
          <h4>
          手機上用 Line 開啟連結有可能無法正確播放影片。<br/><br/> 用 <a href="?openExternalBrowser=1">獨立瀏覽器</a> 觀看課程。
          </h4>
        </Card.Body>
      </Card> 
      : ( ( isAuthenticated ) ? ( 
            <div className="d-grid gap-3"> 
              <Courses />
              <div style={{color:'#fff'}}>[{getUA}]</div>
              <div style={{color:'#ccc'}}>[{userName}]</div>
            </div>
           ) :
          <Card>
            <Card.Header><h2>情緒教育培訓課程說明</h2></Card.Header>
            <Card.Body>
              <h4>
              本系統目前僅供完成團體報名的志工與家長使用。
              詳見 <a href="https://www.happinessvillage.org/join/lesson">培訓課程訊息</a>。
              已報名的學員請由此 <a href="/authentication/login">登入</a> 課程網站。
              </h4>
            </Card.Body>
        </Card>
      )
      
  );
}

export { Home };